@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,700&display=swap");
@import "../../node_modules/simplelightbox/dist/simple-lightbox.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Crimson-Text instead of Minion Pro: */
@font-face {
    font-family: "Crimson Text";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(/public/fonts/CrimsonText-Regular.ttf) format("truetype");
    src: url(https://fonts.gstatic.com/s/crimsontext/v19/wlp2gwHKFkZgtmSR3NB0oRJfbwhT.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074,
        U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: "Crimson Text";
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url(/public/fonts/CrimsonText-Italic.ttf) format("truetype");
    src: url(https://fonts.gstatic.com/s/crimsontext/v19/wlpogwHKFkZgtmSR3NB0oRJfajhRK_Y.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074,
        U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: "Crimson Text";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(/public/fonts/CrimsonText-SemiBold.ttf) format("truetype");
    src: url(https://fonts.gstatic.com/s/crimsontext/v19/wlppgwHKFkZgtmSR3NB0oRJXsCxGDNNQ.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074,
        U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: "Crimson Text";
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url(/public/fonts/CrimsonText-SemiBoldItalic.ttf) format("truetype");
    src: url(https://fonts.gstatic.com/s/crimsontext/v19/wlprgwHKFkZgtmSR3NB0oRJfajCOD-NS_LU.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074,
        U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

main {
    @apply mx-auto w-full px-4 pt-7 text-gray-900 md:w-5/6 md:px-3 md:pt-16 lg:w-3/4 xl:w-2/3 2xl:w-3/5;
}

a {
    @apply text-red-900 hover:text-red-600;
}

audio {
    filter: grayscale(1) saturate(500%) contrast(100%) invert(100%);
    /* mix-blend-mode: multiply; */
}

@-moz-document url-prefix() {
    audio {
        filter: invert(0%);
        /* mix-blend-mode: multiply; */
    }
}

/* "Checkbox Hack" https://stackoverflow.com/a/48469407 : */
#hamburger-menu-checkbox:checked ~ #hamburger-menu-panel {
    @apply !flex !h-screen;
}

#hamburger-menu-checkbox:checked ~ #hamburger-menu-lines > div > div {
    @apply !bg-red-800;
}

/* .cursor-ve:hover {
    cursor:
        url("/public/images/virtAusstCursor.png") 64 64,
        auto;


} */

.cursor-ve {
    @apply hover:cursor-[url('/public/images/virtAusstCursor.png')_64_64,_auto];
}

@media (min-width: 768px) {
    .md\:cursor-ve {
        @apply cursor-ve;
    }
}

.cursor-ve-prev {
    @apply hover:cursor-[url('/public/images/virtAusstCursor_prev.png')_64_64,_auto];
}

.cursor-ve-next {
    @apply hover:cursor-[url('/public/images/virtAusstCursor_next.png')_64_64,_auto];
}

p {
    @apply mb-4;
}

p:last-of-type {
    @apply mb-0;
}
